import React, { useContext, useEffect } from "react"
import {
    Wrapper,
    background1, 
    mq
} from "../styles/globalstyles";
import { gql, useQuery } from "@apollo/client";
import VeranstaltungsreiheForm from "../components/forms/veranstaltungsreihe-form";
import EventSEO from "../SEO/event-seo";
import styled from "@emotion/styled";
import VeranstaltungsModul from "../components/events/module";
import EventContext from "../provider/eventContext";

const EVENT = gql`
  query landingPage($id: ID!) {
    landingPage(where: { id: $id }) {
      id
      createdAt
      titel
      header{
        url
      }
      inhalt {
          html
      }
      kalender {
        googleKalender
        outlookKalender
        ics {
          id
          url
        }
      }
      module {
        id
        modulNummer
        titel
        untertitel
        datum
        kalender {
          googleKalender
          outlookKalender
          ics {
            id
            url
          }
        }
        beschreibungstext {
          html
        }
      }
    }
  }
`

const LandingPageContainer = styled.div(mq({
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: '4rem',
    marginBottom: '4rem',
    maxWidth: "840px",
    width: "100%",
    height: "auto",
    padding: ['0 2rem', '0 2rem', '0 2rem', '0']
}))

const LandingPage = ({ pageContext: { id }, pageContext }) => {
  const {veranstaltungen, vrAddHandler, vrRemoveHandler, vrAddAllHandler, vrRemoveAllHandler} = useContext(EventContext)
  const {data} = useQuery(EVENT, { variables: { id } })

  useEffect(() => {
    if (veranstaltungen.length === 0 && data?.landingPage?.module?.length > 0) {
      let idArray =[]
      data?.landingPage?.module?.map( modul => {
          idArray.push(modul.id
            )})
        vrAddAllHandler(idArray)
  }
  }, [data]);

  return (
    <>
    <Wrapper background={background1}>
        <EventSEO 
            eventTitle={pageContext.titel}
            siteUrl={"https://www.proceed.gmbh/akademie/" + pageContext.slug}
            description={pageContext.untertitel}
            imageUrl={pageContext.header}
            />
            <LandingPageContainer css={{marginTop: '6rem'}}> 
                {data && <img src={data.landingPage.header.url} alt={data.landingPage.titel} />}
            </LandingPageContainer>
            <LandingPageContainer>
                {data && <div dangerouslySetInnerHTML={{'__html': data.landingPage.inhalt.html}} />}
            </LandingPageContainer>
            <LandingPageContainer>
            <div css={{display: "flex", flexDirection: "row", alignItems: "stretch", justifyContent: "space-between", gap: "1.2rem", flexWrap: "wrap", width: "100%", marginTop: "1.2rem"}}>
                {data && data.landingPage.module.length > 0 && data.landingPage.module.map(modul => {
                    return (
                    <VeranstaltungsModul 
                      key={modul.id} 
                      id={modul.id} 
                      modulNummer={modul.modulNummer} 
                      beschreibung={modul.beschreibungstext.html} 
                      titel={modul.titel} 
                      untertitel={modul.untertitel} 
                      datum={modul.datum} 
                      isBookable={false}
                     />
                  )})}
                </div>
            </LandingPageContainer>
            <LandingPageContainer id="anmeldung">
                <h2>Melden Sie sich jetzt an</h2>
                <h3>
                  {pageContext.titel}
                </h3>
                {data && <VeranstaltungsreiheForm veranstaltungen={veranstaltungen} veranstaltungsModule={data?.landingPage?.module} kalender={data.landingPage.kalender} /> }
            </LandingPageContainer>
      </Wrapper>
    </>
  )
};

export default LandingPage
